import EmptyState from '@components/shared/EmptyState'
import FavoriteButton from '@components/shared/FavoriteButton'
import RenderSwapOnlyTokens from '@components/shared/RenderSwapOnlyTokens'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import { Table } from '@components/shared/Table'
import TokenFilters from '@components/shared/TokenFilters'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import SearchInput from 'components/SearchInput/SearchInput'
import { APRCell } from 'components/Synthetics/GmList/APRCell'
import { CLOUD_FRONT_URL } from 'config/constants'
import { getMarketIndexName, MarketInfo } from 'domain/synthetics/markets'
import { formatTokenAmount } from 'rfx/lib/numbers'
import useBreakpoints from 'hooks/useBreakpoints'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import useTotalTVLperCollateral from 'hooks/useTotalTVLperCollateral'
import Image from 'next/image'
import { useRouter } from 'next/router'
import ArrowDown from 'public/icons/arrow-down-grey.svg'
import { useCallback, useMemo, useState } from 'react'
import useSWR from 'swr'

export const tokenFilters: Record<string, { label: string; count: number }> = {
  All: {
    label: 'All',
    count: 0,
  },
  New: {
    label: 'New',
    count: 0,
  },
  Crypto: {
    label: 'Crypto',
    count: 0,
  },
  Forex: {
    label: 'Forex',
    count: 0,
  },
  Commodities: {
    label: 'Commodities',
    count: 0,
  },
}

interface Props {
  selectedPool: MarketInfo
  tableData: PoolDataType[]
}

const EarnPoolSelector = ({ selectedPool, tableData }: Props) => {
  const router = useRouter()
  const { above } = useBreakpoints()
  const { setMarketDetailAddress } = useCreatePoolStore()

  const [searchQuery, setSearchQuery] = useState('')
  const [appliedFilter, setAppliedFilter] = useState('All')

  const totalTVLgrouped = useTotalTVLperCollateral()
  const { data: merklData } = useSWR(['merkl-data'])

  const filterDataByMarketIndex = (data: PoolDataType, marketIndex: string) =>
    data?.marketIndexName.toLowerCase().includes(marketIndex.toLowerCase())

  const filteredPoolOptions = useMemo(() => {
    let filteredData = tableData

    filteredData = filteredData?.filter(() => {
      switch (appliedFilter) {
        case 'All':
        case 'Crypto':
        case 'New':
          return true
        default:
          return false
      }
    })

    if (searchQuery.trim() !== '') {
      filteredData = filteredData?.filter((item) =>
        filterDataByMarketIndex(item, searchQuery),
      )
    }
    return filteredData
  }, [tableData, appliedFilter, searchQuery])

  const updateTokenFiltersCount = useMemo(() => {
    const updatedFilters = { ...tokenFilters }
    Object.keys(updatedFilters).forEach((key) => {
      switch (key) {
        case 'All':
        case 'Crypto':
        case 'New':
          updatedFilters[key].count = tableData.length || 0
          break
        case 'Forex':
        case 'Commodities':
          updatedFilters[key].count = 0
          break
        default:
          break
      }
    })
    return Object.keys(updatedFilters).map((key) => {
      return {
        label: updatedFilters[key].label,
        count: updatedFilters[key].count,
      }
    })
  }, [tableData])

  const handleTokenFilterChange = useCallback((val: string) => {
    setAppliedFilter(val)
  }, [])

  const handlePoolClick = (row: PoolDataType) => {
    const address = row.market?.marketTokenAddress || ''
    setMarketDetailAddress(address)
    router.push({
      pathname: '/earn',
      search: `?market=${address}`,
    })
  }

  const columns: ColumnDef<PoolDataType>[] = [
    {
      accessorKey: 'market',
      header: 'MARKET',
      meta: {
        align: 'left',
      },
      cell: (info) => {
        const { indexToken, marketPoolName, market, marketIndexName } =
          info.row.original
        return (
          <div className="flex flex-1 items-center gap-2">
            <div className="order-2 lg:order-1">
              {market?.isSpotOnly ? (
                <RenderSwapOnlyTokens
                  indexTokenSymbol={indexToken.symbol}
                  marketPoolName={marketPoolName}
                  size={above.lg ? 20 : 16}
                />
              ) : (
                <RenderTokenIcon
                  symbol={indexToken.symbol}
                  size={above.lg ? 'small' : 'smallest'}
                />
              )}
            </div>
            <div className="order-1 lg:order-2">
              <div className="font-medium text-th-fgd-1">{marketIndexName}</div>
            </div>
          </div>
        )
      },
    },
    {
      accessorKey: 'totalSupply',
      header: 'TOTAL SUPPLY',
      meta: {
        align: 'left',
      },
      cell: (info) => {
        const { token, totalSupply } = info.row.original
        return (
          <span className="text-sm font-medium text-th-fgd-1">
            {formatTokenAmount(totalSupply, token.decimals, 'RP', {
              useCommas: true,
              displayDecimals: 2,
            })}
          </span>
        )
      },
    },
    {
      accessorKey: 'balance',
      header: 'WALLET',
      meta: {
        align: 'left',
      },
      cell: (info) => {
        const { token } = info.row.original
        return (
          <span className="text-sm font-medium text-th-fgd-1">
            {formatTokenAmount(token.balance, token.decimals, 'RP', {
              useCommas: true,
              displayDecimals: 2,
              fallbackToZero: true,
            })}
          </span>
        )
      },
    },
    {
      accessorKey: 'apr',
      header: 'APR',
      meta: {
        align: 'left',
      },
      cell: (info) => {
        const aprData = info.row.original
        const { market } = aprData

        return (
          <div className="flex items-center">
            <p className="text-sm font-medium leading-[14px] text-th-fgd-1">
              <APRCell
                market={aprData}
                totalTVLgrouped={totalTVLgrouped}
                merklData={merklData}
              />
            </p>
            {above.lg && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="flex flex-1 justify-center"
              >
                <FavoriteButton
                  item={market?.marketTokenAddress || ''}
                  type="pool"
                />
              </div>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <Popover>
      {({ open }) => {
        if (!open && searchQuery.length > 0) {
          setSearchQuery('')
        }

        const isSwapOnly = selectedPool?.name.includes('SWAP-ONLY')
        const swapOnlyTokens = isSwapOnly
          ? selectedPool?.name?.split(' ')[1]?.replaceAll(/\[|\]/g, '')
          : undefined

        return (
          <>
            <PopoverButton
              as="button"
              className="flex cursor-pointer items-center gap-4 border-none bg-transparent text-th-fgd-1"
            >
              <div className="flex h-full w-max items-center gap-2">
                <div className="order-2 flex items-end justify-center gap-1 lg:order-1">
                  {isSwapOnly ? (
                    <RenderSwapOnlyTokens
                      indexTokenSymbol={selectedPool?.indexToken.symbol}
                      marketPoolName={swapOnlyTokens}
                      size={above.lg ? 20 : 16}
                    />
                  ) : selectedPool?.isSpotOnly ? (
                    <RenderSwapOnlyTokens
                      indexTokenSymbol={selectedPool?.indexToken.symbol}
                      marketPoolName={selectedPool?.name}
                      size={above.lg ? 20 : 16}
                    />
                  ) : (
                    <RenderTokenIcon
                      symbol={selectedPool?.indexToken.symbol}
                      size={above.lg ? 'regular' : 'small'}
                    />
                  )}
                </div>
                <div className="order-1 flex flex-col gap-2 lg:order-2">
                  <p className="flex items-center space-x-2">
                    <span className="text-sm font-semibold leading-3 text-th-fgd-1">
                      {getMarketIndexName({
                        indexToken: selectedPool.indexToken,
                        isSpotOnly: !!selectedPool?.isSpotOnly,
                      })}
                    </span>
                  </p>
                  <p className="text-start text-xs font-medium text-th-fgd-3">
                    {selectedPool.name}
                  </p>
                </div>
              </div>
              <ArrowDown className={clsx(open && 'rotate-180')} />
            </PopoverButton>
            <PopoverPanel
              as="div"
              className="absolute left-0 top-full h-[224px] w-full max-w-[51rem] rounded-md lg:h-[506px]"
            >
              <div
                className="absolute left-0 top-0 z-10 h-full w-full rounded-md bg-th-bkg-5 opacity-80"
                style={{
                  opacity: 0.8,
                }}
              />

              <div className="market-token-selector-glass-bg absolute left-0 top-0 z-10 h-full w-full" />

              <Image
                alt=""
                className="pointer-events-none absolute z-20 h-full w-full"
                layout="fill"
                src={CLOUD_FRONT_URL + '/images/bg-noise.png'}
              />

              <div className="relative z-10 flex h-full flex-col gap-3 p-4">
                {above.lg && (
                  <SearchInput
                    className="h-12"
                    inputBoxClasses="h-12 select-none"
                    value={searchQuery}
                    setValue={(event) => setSearchQuery(event.target.value)}
                  />
                )}
                <div className="mb-1">
                  <TokenFilters
                    appliedFilter={appliedFilter}
                    changeFilter={handleTokenFilterChange}
                    options={updateTokenFiltersCount}
                    forModal
                  />
                </div>

                <div className="flex-1 overflow-y-auto">
                  {filteredPoolOptions?.length === 0 ? (
                    <EmptyState
                      text="No Tokens Available"
                      className="h-full w-full"
                    />
                  ) : (
                    <div className="flex h-full w-full flex-col overflow-x-hidden">
                      <Table
                        rows={filteredPoolOptions}
                        columns={columns}
                        handleRowClick={handlePoolClick}
                      />
                    </div>
                  )}
                </div>
              </div>
            </PopoverPanel>
          </>
        )
      }}
    </Popover>
  )
}

export default EarnPoolSelector
