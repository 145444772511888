import { Provider } from '@ethersproject/providers'
import { GAS_PRICE_ADJUSTMENT_MAP, MAX_GAS_PRICE_MAP } from 'config/chains'
import { IS_VERBOSE } from 'config/development'
import { BigNumber, Contract } from 'ethers'

export async function setGasPrice(
  txnOpts: any,
  provider: Provider,
  chainId: number,
) {
  let maxGasPrice: any = BigNumber.from(MAX_GAS_PRICE_MAP[chainId] || 0)
  const premium: any = GAS_PRICE_ADJUSTMENT_MAP[chainId] || BigNumber.from('0')

  const gasPrice = await provider.getGasPrice()

  if (maxGasPrice.gt(0)) {
    if (gasPrice.gt(maxGasPrice)) {
      maxGasPrice = gasPrice
    }
    const feeData = await provider.getFeeData()
    // the wallet provider might not return maxPriorityFeePerGas in feeData
    // in which case we should fallback to the usual getGasPrice flow handled below
    if (feeData && feeData.maxPriorityFeePerGas) {
      txnOpts.maxFeePerGas = maxGasPrice
      txnOpts.maxPriorityFeePerGas = feeData.maxPriorityFeePerGas.add(premium)
      return
    }
  }

  txnOpts.gasPrice = gasPrice.add(premium)
  return
}

export async function getGasLimit(
  contract: Contract,
  method: any,
  params: any[] = [],
  value?: BigNumber | number,
) {
  const defaultValue = BigNumber.from(0)
  if (!value) {
    value = defaultValue
  }
  let gasLimit = BigNumber.from(0)
  try {
    gasLimit = await contract.estimateGas[method](...params, { value })
  } catch (e) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.log({ e })
  }
  if (gasLimit.lt(22000)) {
    gasLimit = BigNumber.from('7920027')
  }

  return gasLimit.mul(11).div(10) // add a 10% buffer
}

export function getBestNonce(providers: any[]): Promise<number> {
  const MAX_NONCE_NEEDED = 3
  const MAX_WAIT = 5000
  const ONE_MORE_WAIT = 1000

  return new Promise(async (resolve, reject) => {
    const results: number[] = []
    let resolved = false

    const handleResolve = () => {
      resolved = true

      if (results.length) {
        resolve(Math.max(...results))
      } else {
        reject(new Error('Failed to fetch nonce from any provider'))
      }
    }

    let timerId = setTimeout(handleResolve, MAX_WAIT)

    const setResolveTimeout = (time: number) => {
      clearTimeout(timerId)

      if (resolved) {
        return
      }

      if (time) {
        timerId = setTimeout(handleResolve, time)
      } else {
        handleResolve()
      }
    }

    await Promise.all(
      providers.map((provider, i) =>
        provider
          .getNonce('pending')
          .then((nonce: any) => results.push(nonce))
          .then(() => {
            if (
              results.length === providers.length ||
              results.length >= MAX_NONCE_NEEDED
            ) {
              setResolveTimeout(0)
            } else {
              setResolveTimeout(ONE_MORE_WAIT)
            }
          })
          .catch((error: any) => {
            IS_VERBOSE &&
              // eslint-disable-next-line no-console
              console.error(
                `Error fetching nonce from provider ${i}: ${error.message}`,
              )
          }),
      ),
    )

    setResolveTimeout(0)
  })
}
