import { CHART_PERIODS } from '@components/trade/rfx/tradingview/lib/legacy'
import { XbyYPairs } from 'config/XbyYPairs'
import { IS_VERBOSE } from 'config/development'
import { getOracleKeeperUrl } from 'config/oracleKeeper'
import { TOKENS, getNormalizedTokenSymbol } from 'config/tokens'
import { useSettings } from 'context/SettingsContext/SettingsContextProvider'
import { TIMEZONE_OFFSET } from 'domain/prices'
import { Bar } from 'domain/tradingview/types'
import { buildUrl } from 'rfx/lib/buildUrl'
import { useLocalStorageSerializeKey } from 'rfx/lib/localStorage'
import { useMemo } from 'react'

export type TickersResponse = {
  minPrice: string
  maxPrice: string
  oracleDecimals: number
  tokenSymbol: string
  tokenAddress: string
  updatedAt: number
}[]

export type DayPriceCandle = {
  tokenSymbol: string
  high: number
  low: number
  open: number
  close: number
  deltaPercentage: number
  deltaPercentageStr: string
  deltaPrice: number
}

export type RawIncentivesStats = {
  lp: {
    isActive: boolean
    totalRewards: string
    period: number
    rewardsPerMarket: Record<string, string>
  }
  migration: {
    isActive: boolean
    maxRebateBps: number
    period: number
  }
  trading:
    | {
        isActive: true
        rebatePercent: number
        allocation: string
        period: number
      }
    | {
        isActive: false
      }
}

const pythResolution: Record<string, string> = {
  '1m': '1',
  '3m': '3',
  '5m': '5',
  '15m': '15',
  '30m': '30',
  '1h': '60',
  '2h': '120',
  '4h': '240',
  '1d': '1D',
  '1w': '1W',
}

const pythFromCandle: Record<string, number> = {
  '1d': 17280000,
  '1w': 17280000,
  '4h': 2629743,
  '1h': 2629743,
  '15m': 2629743,
  '5m': 2629743,
  '1m': 345600,
}

export type OracleKeeperFetcher = ReturnType<typeof useOracleKeeperFetcher>

function parseOracleCandle(rawCandle: number[]): Bar {
  const [timestamp, open, high, low, close] = rawCandle

  return {
    time: timestamp + TIMEZONE_OFFSET,
    open,
    high,
    low,
    close,
  }
}

// let fallbackThrottleTimerId

export function useOracleKeeperFetcher(chainId: number) {
  const { oracleKeeperInstancesConfig, setOracleKeeperInstancesConfig } =
    useSettings()
  const oracleKeeperIndex =
    oracleKeeperInstancesConfig && oracleKeeperInstancesConfig[chainId]
  const oracleKeeperUrl = getOracleKeeperUrl(chainId, oracleKeeperIndex)
  const [forceIncentivesActive] = useLocalStorageSerializeKey(
    'forceIncentivesActive',
    false,
  )

  return useMemo(() => {
    // const switchOracleKeeper = () => {
    //   if (fallbackThrottleTimerId) {
    //     return
    //   }

    //   const nextIndex = getOracleKeeperNextIndex(chainId, oracleKeeperIndex)

    //   if (nextIndex === oracleKeeperIndex) {
    //     console.error(`no available oracle keeper for chain ${chainId}`)
    //     return
    //   }

    //   console.log(
    //     `switch oracle keeper to ${getOracleKeeperUrl(chainId, nextIndex)}`,
    //   )

    //   setOracleKeeperInstancesConfig((old) => {
    //     return { ...old, [chainId]: nextIndex }
    //   })

    //   fallbackThrottleTimerId = setTimeout(() => {
    //     fallbackThrottleTimerId = undefined
    //   }, 5000)
    // }

    function fetchTickers(): Promise<TickersResponse> {
      return fetch(buildUrl(oracleKeeperUrl!, '/prices/tickers'))
        .then((res) => res.json())
        .then((res) => {
          if (!res.length) {
            throw new Error('Invalid tickers response')
          }

          return res
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.error(e)
          // switchOracleKeeper()

          throw e
        })
    }

    function createPriceData(
      symbol: string,
      assetData: Record<string, string | number>,
    ): DayPriceCandle {
      return {
        tokenSymbol: symbol,
        high: assetData.HIGHDAY as number,
        low: assetData.LOWDAY as number,
        open: assetData.OPENDAY as number,
        close: assetData.PRICE as number,
        deltaPrice: assetData.CHANGE24HOUR as number,
        deltaPercentage: assetData.CHANGEPCT24HOUR as number,
        deltaPercentageStr: (assetData.CHANGEPCT24HOUR + '%') as string,
      }
    }

    function fetch24hPrices<TResult extends boolean>(
      resultObj?: TResult,
    ): TResult extends true
      ? Promise<Record<string, DayPriceCandle>>
      : Promise<DayPriceCandle[]>
    function fetch24hPrices<TResult extends boolean>(
      resultObj?: TResult,
    ): Promise<Record<string, DayPriceCandle> | DayPriceCandle[]> {
      return fetch(
        buildUrl('https://min-api.cryptocompare.com', '/data/pricemultifull', {
          fsyms: TOKENS[chainId]
            .map((t) => (t.symbol === 'wstETH' ? 'stETH' : t.symbol))
            .join(','),
          tsyms: 'BTC,USD',
        }),
      )
        .then((res) => res.json())
        .then((res) => {
          if (!!resultObj) {
            const priceDelta: Record<string, DayPriceCandle> = {}

            Object.keys(res.RAW).forEach((item) => {
              if (item === 'ETH') {
                priceDelta[item + '/BTC'] = createPriceData(
                  item + '/BTC',
                  res.RAW[item].BTC,
                )
              }
              priceDelta[item] = createPriceData(item, res.RAW[item].USD)
            })
            return priceDelta
          } else {
            const priceDelta: DayPriceCandle[] = []

            Object.keys(res.RAW).forEach((item) => {
              if (item === 'ETH') {
                priceDelta.push(
                  createPriceData(item + '/BTC', res.RAW[item].BTC),
                )
              }
              priceDelta.push(createPriceData(item, res.RAW[item].USD))
            })
            return priceDelta
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.error(e)
          // switchOracleKeeper()
          throw e
        })
    }

    async function fetchPythOracleCandles({
      period,
      symbol,
      from,
      to,
    }: {
      symbol: string
      period: string
      limit?: number
      from?: number
      to?: number
    }): Promise<Bar[]> {
      symbol = getNormalizedTokenSymbol(symbol)
      const periodSeconds = CHART_PERIODS[period]

      const fromCandle =
        from ||
        Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds -
          pythFromCandle[period]
      const toCandle =
        to || Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds

      const ONE_YEAR = 365 * 24 * 60 * 60 // seconds in a year
      const timeDiff = toCandle - fromCandle

      if (timeDiff > ONE_YEAR) {
        // Need to make multiple calls for periods longer than 1 year
        const numCalls = Math.ceil(timeDiff / ONE_YEAR)
        const promises: Promise<Bar[]>[] = []

        for (let i = 0; i < numCalls; i++) {
          const callFromCandle = fromCandle + i * ONE_YEAR
          const callToCandle = Math.min(callFromCandle + ONE_YEAR, toCandle)

          promises.push(
            fetch(
              buildUrl(
                'https://benchmarks.pyth.network/v1',
                '/shims/tradingview/history',
                {
                  symbol: XbyYPairs.includes(symbol)
                    ? 'Crypto.' + symbol
                    : 'Crypto.' + symbol + '/USD',
                  resolution: pythResolution[period],
                  from: callFromCandle,
                  to: callToCandle,
                },
              ),
            )
              .then((res) => res.json())
              .then((res) => {
                return res?.t.map((item: any, index: number) => ({
                  time: item + TIMEZONE_OFFSET,
                  open: res.o[index],
                  high: res.h[index],
                  low: res.l[index],
                  close: res.c[index],
                }))
              }),
          )
        }

        return Promise.all(promises)
          .then((results) => results.flat())
          .catch((e) => {
            // eslint-disable-next-line no-console
            IS_VERBOSE && console.error(e)
            return []
          })
      }

      // Single call for periods less than 1 year
      return fetch(
        buildUrl(
          'https://benchmarks.pyth.network/v1',
          '/shims/tradingview/history',
          {
            symbol: XbyYPairs.includes(symbol)
              ? 'Crypto.' + symbol
              : 'Crypto.' + symbol + '/USD',
            resolution: pythResolution[period],
            from: fromCandle,
            to: toCandle,
          },
        ),
      )
        .then((res) => res.json())
        .then((res) => {
          const result = res?.t.map((item: any, index: number) => ({
            time: item + TIMEZONE_OFFSET,
            open: res.o[index],
            high: res.h[index],
            low: res.l[index],
            close: res.c[index],
          }))

          return result
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.error(e)
        })
    }

    async function fetchOracleCandles(
      tokenSymbol: string,
      period: string,
      limit: number,
    ): Promise<Bar[]> {
      tokenSymbol = getNormalizedTokenSymbol(tokenSymbol)

      return fetch(
        buildUrl(oracleKeeperUrl!, '/prices/candles', {
          tokenSymbol,
          period,
          limit,
        }),
      )
        .then((res) => res.json())
        .then((res) => {
          if (
            !Array.isArray(res.candles) ||
            (res.candles.length === 0 && limit > 0)
          ) {
            throw new Error('Invalid candles response')
          }
          return res.candles.map(parseOracleCandle)
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.error(e)
          // switchOracleKeeper()
          throw e
        })
    }

    async function fetchIncentivesRewards(): Promise<RawIncentivesStats | null> {
      return fetch(
        buildUrl(oracleKeeperUrl!, '/incentives/stip', {
          ignoreStartDate: forceIncentivesActive ? '1' : undefined,
        }),
      )
        .then((res) => res.json())
        .catch((e) => {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.error(e)
          // switchOracleKeeper()
          return null
        })
    }

    return {
      oracleKeeperUrl,
      fetchTickers,
      fetch24hPrices,
      fetchOracleCandles,
      fetchPythOracleCandles,
      fetchIncentivesRewards,
    }
  }, [
    chainId,
    forceIncentivesActive,
    oracleKeeperIndex,
    oracleKeeperUrl,
    setOracleKeeperInstancesConfig,
  ])
}
