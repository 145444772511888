import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ReactNode } from 'react'
import { cn } from 'utils/classnames'
import ArrorDown from '../public/icons/accordian-arrow.svg'

interface TableRowAccordianProps {
  VisibleContent: string | ReactNode
  HiddenContent: string | ReactNode
  SubRowContent?: string | ReactNode
  handleOnClick?: () => void
  className?: string
  buttonClass?: string
  panelClass?: string
}

const TableRowAccordian = ({
  className,
  VisibleContent,
  HiddenContent,
  SubRowContent,
  handleOnClick,
  buttonClass,
  panelClass,
}: TableRowAccordianProps) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div onClick={handleOnClick} className={className}>
          <div
            className={cn(
              'flex w-full items-center justify-between px-4',
              buttonClass,
            )}
          >
            <div className="flex-1">{VisibleContent}</div>

            <DisclosureButton
              className="cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            >
              <ArrorDown
                width={24}
                height={24}
                className={cn(open ? 'rotate-180 text-th-active' : 'rotate-0')}
              />
            </DisclosureButton>
          </div>
          {Boolean(SubRowContent && !open) && (
            <div className="flex justify-end border-b border-th-input-border px-4">
              {SubRowContent}
            </div>
          )}
          <DisclosurePanel className={cn('px-4', panelClass)}>
            {HiddenContent}
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  )
}

export default TableRowAccordian
