import { ethers } from 'ethers'
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  AVALANCHE,
  AVALANCHE_FUJI,
  BCS_MAINNET,
  BCS_TESTNET,
  ARBITRUM_SEPOLIA,
  ZKSYNC_SEPOLIA,
  ZKSYNC,
  LINEA,
  OPTIMISM,
  BASE,
  POLYGON,
  ETH_MAINNET,
} from './chains'
import { Address } from 'viem'
import { isProd } from './constants'

const { AddressZero } = ethers.constants

export const XGMT_EXCLUDED_ACCOUNTS = [
  '0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5',
  '0xd9b1c23411adbb984b1c4be515fafc47a12898b2',
  '0xa633158288520807f91ccc98aa58e0ea43acb400',
  '0xffd0a93b4362052a336a7b22494f1b77018dd34b',
]

const CONTRACTS: Record<number, Record<string, string>> = {
  [ETH_MAINNET]: {
    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  [BASE]: {
    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  [POLYGON]: {
    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  [OPTIMISM]: {
    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  [LINEA]: {
    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  [BCS_MAINNET]: {
    // bsc mainnet
    Treasury: '0xa44E7252a0C137748F523F112644042E5987FfC7',
    BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    GMT: '0x99e92123eB77Bc8f999316f622e5222498438784',
    Vault: '0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B',
    Router: '0xD46B23D042E976F8666F554E928e0Dc7478a8E1f',
    Reader: '0x087A618fD25c92B61254DBe37b09E5E8065FeaE7',
    AmmFactory: '0xBCfCcbde45cE874adCB698cC183deBcF17952812',
    AmmFactoryV2: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
    OrderBook: '0x1111111111111111111111111111111111111111',
    OrderBookReader: '0x1111111111111111111111111111111111111111',
    GmxMigrator: '0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9',
    USDG: '0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7',
    NATIVE_TOKEN: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    XGMT: '0xe304ff0983922787Fd84BC9170CD21bF78B16B10',
    GMT_USDG_PAIR: '0xa41e57459f09a126F358E118b693789d088eA8A0',
    XGMT_USDG_PAIR: '0x0b622208fc0691C2486A3AE6B7C875b4A174b317',
    GMT_USDG_FARM: '0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422',
    XGMT_USDG_FARM: '0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759',
    USDG_YIELD_TRACKER: '0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89',
    XGMT_YIELD_TRACKER: '0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80',
    GMT_USDG_FARM_TRACKER_XGMT: '0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd',
    GMT_USDG_FARM_TRACKER_NATIVE: '0xd8E26637B34B2487Cad1f91808878a391134C5c2',
    XGMT_USDG_FARM_TRACKER_XGMT: '0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8',
    XGMT_USDG_FARM_TRACKER_NATIVE: '0x22458CEbD14a9679b2880147d08CA1ce5aa40E84',
    AUTO: '0xa184088a740c695E156F91f5cC086a06bb78b827',
    AUTO_USDG_PAIR: '0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50',
    AUTO_USDG_FARM: '0xE6958298328D02051769282628a3b4178D0F3A47',
    AUTO_USDG_FARM_TRACKER_XGMT: '0x093b8be41c1A30704De84a9521632f9a139c08bd',
    AUTO_USDG_FARM_TRACKER_NATIVE: '0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A',
    GMT_GMX_IOU: '0x47052469970C2484729875CC9E2dd2683fcE71fb',
    XGMT_GMX_IOU: '0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B',
    GMT_USDG_GMX_IOU: '0x481312655F81b5e249780A6a49735335BF6Ca7f4',
    XGMT_USDG_GMX_IOU: '0x8095F1A92526C304623483018aA28cC6E62EB1e1',
  },
  [BCS_TESTNET]: {
    // bsc testnet
    Vault: '0x1B183979a5cd95FAF392c8002dbF0D5A1C687D9a',
    Router: '0x10800f683aa564534497a5b67F45bE3556a955AB',
    Reader: '0x98D4742F1B6a821bae672Cd8721283b91996E454',
    AmmFactory: '0x6725f303b657a9451d8ba641348b6761a6cc7a17',
    AmmFactoryV2: '0x1111111111111111111111111111111111111111',
    OrderBook: '0x9afD7B4f0b58d65F6b2978D3581383a06b2ac4e9',
    OrderBookReader: '0x0713562970D1A802Fa3FeB1D15F9809943982Ea9',
    GmxMigrator: '0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9',
    USDG: '0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028',
    GMT: '0xedba0360a44f885ed390fad01aa34d00d2532817',
    NATIVE_TOKEN: '0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2',
    XGMT: '0x28cba798eca1a3128ffd1b734afb93870f22e613',
    GMT_USDG_PAIR: '0xe0b0a315746f51932de033ab27223d85114c6b85',
    XGMT_USDG_PAIR: '0x0108de1eea192ce8448080c3d90a1560cf643fa0',
    GMT_USDG_FARM: '0xbe3cB06CE03cA692b77902040479572Ba8D01b0B',
    XGMT_USDG_FARM: '0x138E92195D4B99CE3618092D3F9FA830d9A69B4b',
    USDG_YIELD_TRACKER: '0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f',
    XGMT_YIELD_TRACKER: '0x5F235A582e0993eE9466FeEb8F7B4682993a57d0',
    GMT_USDG_FARM_TRACKER_XGMT: '0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C',
    GMT_USDG_FARM_TRACKER_NATIVE: '0xd691B26E544Fe370f39A776964c991363aF72e56',
    XGMT_USDG_FARM_TRACKER_XGMT: '0xfd5617CFB082Ba9bcD62d654603972AE312bC695',
    XGMT_USDG_FARM_TRACKER_NATIVE: '0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28',
    GMT_GMX_IOU: '0x47052469970C2484729875CC9E2dd2683fcE71fb',
    XGMT_GMX_IOU: '0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B',
    GMT_USDG_GMX_IOU: '0x481312655F81b5e249780A6a49735335BF6Ca7f4',
    XGMT_USDG_GMX_IOU: '0x8095F1A92526C304623483018aA28cC6E62EB1e1',
  },
  [ARBITRUM_GOERLI]: {
    // arbitrum testnet
    Vault: AddressZero,
    Router: AddressZero,
    VaultReader: AddressZero,
    Reader: AddressZero,
    GlpManager: AddressZero,
    RewardRouter: AddressZero,
    RewardReader: AddressZero,
    GlpRewardRouter: AddressZero,
    NATIVE_TOKEN: '0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3',
    GLP: AddressZero,
    GMX: AddressZero,
    ES_GMX: AddressZero,
    BN_GMX: AddressZero,
    USDG: AddressZero,
    ES_GMX_IOU: AddressZero,

    StakedGmxTracker: AddressZero,
    BonusGmxTracker: AddressZero,
    FeeGmxTracker: AddressZero,
    StakedGlpTracker: AddressZero,
    FeeGlpTracker: AddressZero,

    StakedGmxDistributor: AddressZero,
    StakedGlpDistributor: AddressZero,

    GmxVester: AddressZero,
    GlpVester: AddressZero,

    OrderBook: AddressZero,
    OrderExecutor: AddressZero,
    OrderBookReader: AddressZero,

    PositionRouter: AddressZero,
    PositionManager: AddressZero,

    TraderJoeGmxAvaxPool: AddressZero,
    ReferralStorage: '0x995E905E471D53B7c5d0dbf6406860Cb3C029e95',
    ReferralReader: AddressZero,

    // Synthetics
    DataStore: '0xbA2314b0f71ebC705aeEBeA672cc3bcEc510D03b',
    EventEmitter: '0x2fbE45fCb58B7106CF0a3Be9225D5Ed5A1004cc4',
    ExchangeRouter: '0xFE98518C9c8F1c5a216E999816c2dE3199f295D2',
    DepositVault: '0x838a9bdf8736eD522A60F5f715e4F3FC2BC91A08',
    WithdrawalVault: '0xaAac001C2a2727Ff2d484C4Ad7d2079C7094e7Ef',
    OrderVault: '0x82aFd2590814a7Ce3d7ea6b63F80481F8b227bA9',
    SyntheticsReader: '0xab747a7bb64B74D78C6527C1F148808a19120475',
    SyntheticsRouter: '0xa960786Bc30F8587279df6116F9E0B15C5b034dE',
    Timelock: AddressZero,

    Multicall: '0x6d85594c9BD6b0833bC85AE62B360654A1e52D70',
  },
  [ARBITRUM_SEPOLIA]: {
    // arbitrum testnet
    Vault: AddressZero,
    Router: '0xC82DD8406aF3b1b93cC264Ff27ef73bfe9fE8937',
    VaultReader: AddressZero,
    Reader: '0x504f13f3e796D8240A332818191f1Bace3a47E9a',
    GlpManager: AddressZero,
    RewardRouter: AddressZero,
    RewardReader: AddressZero,
    GlpRewardRouter: AddressZero,
    NATIVE_TOKEN: AddressZero,
    GLP: AddressZero,
    GMX: AddressZero,
    ES_GMX: AddressZero,
    BN_GMX: AddressZero,
    USDG: AddressZero,
    ES_GMX_IOU: AddressZero,

    StakedGmxTracker: AddressZero,
    BonusGmxTracker: AddressZero,
    FeeGmxTracker: AddressZero,
    StakedGlpTracker: AddressZero,
    FeeGlpTracker: AddressZero,

    StakedGmxDistributor: AddressZero,
    StakedGlpDistributor: AddressZero,

    GmxVester: AddressZero,
    GlpVester: AddressZero,

    OrderBook: AddressZero,
    OrderExecutor: AddressZero,
    OrderBookReader: AddressZero,

    PositionRouter: AddressZero,
    PositionManager: AddressZero,

    TraderJoeGmxAvaxPool: AddressZero,
    ReferralStorage: '0xe89E505E9beFB9b501a5E519F0FaFA176e731931',
    ReferralReader: AddressZero,

    // Synthetics
    DataStore: '0x7E2853eC825c02eCfe9075381Ee3f20D8242893A',
    EventEmitter: '0x54b0eA416465a14570a10c5d700ACf65BD888FeF',
    ExchangeRouter: '0xe8A9EdEbE3B1fcf68d233ae0aD6399eDfDb5ad63',
    DepositVault: '0xB7Fd75dFAB244085D273A032368e527524CC7377',
    WithdrawalVault: '0xDec04A44C9961d261314defAD1826F8A6125D9ac',
    OrderVault: '0xc241FC8475D90b60444849b9C0c5A314cB18F74D',
    SyntheticsReader: '0x504f13f3e796D8240A332818191f1Bace3a47E9a',
    SyntheticsRouter: '0xC82DD8406aF3b1b93cC264Ff27ef73bfe9fE8937',
    Timelock: '0x60f9fbFB8A7aBbc41DD24B0C27bA80e2F2180E0c',

    Multicall: '0x60f9fbFB8A7aBbc41DD24B0C27bA80e2F2180E0c',
  },
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: '0x489ee077994B6658eAfA855C308275EAd8097C4A',
    Router: '0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064',
    VaultReader: '0xfebB9f4CAC4cD523598fE1C5771181440143F24A',
    Reader: '0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694',
    GlpManager: '0x3963FfC9dff443c2A94f21b129D429891E32ec18',
    RewardRouter: '0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1',
    GlpRewardRouter: '0xB95DB5B167D75e6d04227CfFFA61069348d271F5',
    RewardReader: '0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0',
    NATIVE_TOKEN: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    GLP: '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258',
    GMX: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
    ES_GMX: '0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA',
    BN_GMX: '0x35247165119B69A40edD5304969560D0ef486921',
    USDG: '0x45096e7aA921f27590f8F19e457794EB09678141',
    ES_GMX_IOU: '0x6260101218eC4cCfFF1b778936C6f2400f95A954',
    StakedGmxTracker: '0x908C4D94D34924765f1eDc22A1DD098397c59dD4',
    BonusGmxTracker: '0x4d268a7d4C16ceB5a606c173Bd974984343fea13',
    FeeGmxTracker: '0xd2D1162512F927a7e282Ef43a362659E4F2a728F',
    StakedGlpTracker: '0x1aDDD80E6039594eE970E5872D247bf0414C8903',
    FeeGlpTracker: '0x4e971a87900b931fF39d1Aad67697F49835400b6',

    StakedGmxDistributor: '0x23208B91A98c7C1CD9FE63085BFf68311494F193',
    StakedGlpDistributor: '0x60519b48ec4183a61ca2B8e37869E675FD203b34',

    GmxVester: '0x199070DDfd1CFb69173aa2F7e20906F26B363004',
    GlpVester: '0xA75287d2f8b217273E7FCD7E86eF07D33972042E',

    OrderBook: '0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB',
    OrderExecutor: '0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB',
    OrderBookReader: '0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21',

    PositionRouter: '0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868',
    PositionManager: '0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C',

    UniswapGmxEthPool: '0x80A9ae39310abf666A87C743d6ebBD0E8C42158E',
    ReferralStorage: '0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d',
    ReferralReader: '0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8',

    // Synthetics
    DataStore: '0xFD70de6b91282D8017aA4E741e9Ae325CAb992d8',
    EventEmitter: '0xC8ee91A54287DB53897056e12D9819156D3822Fb',
    ExchangeRouter: '0x7C68C7866A64FA2160F78EEaE12217FFbf871fa8',
    DepositVault: '0xF89e77e8Dc11691C9e8757e84aaFbCD8A67d7A55',
    WithdrawalVault: '0x0628D46b5D145f183AdB6Ef1f2c97eD1C4701C55',
    OrderVault: '0x31eF83a530Fde1B38EE9A18093A333D8Bbbc40D5',
    SyntheticsReader: '0xf60becbba223EEA9495Da3f606753867eC10d139',
    SyntheticsRouter: '0x7452c558d45f8afC8c83dAe62C3f8A5BE19c71f6',
    Timelock: '0xaa50bD556CE0Fe61D4A57718BA43177a3aB6A597',

    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  [AVALANCHE]: {
    // avalanche
    Vault: '0x9ab2De34A33fB459b538c43f251eB825645e8595',
    Router: '0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8',
    VaultReader: '0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D',
    Reader: '0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd',
    GlpManager: '0xD152c7F25db7F4B95b7658323c5F33d176818EE4',
    RewardRouter: '0x82147C5A7E850eA4E28155DF107F2590fD4ba327',
    GlpRewardRouter: '0xB70B91CE0771d3f4c81D87660f71Da31d48eB3B3',
    RewardReader: '0x04Fc11Bd28763872d143637a7c768bD96E44c1b6',
    NATIVE_TOKEN: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    GLP: '0x01234181085565ed162a948b6a5e88758CD7c7b8',
    GMX: '0x62edc0692BD897D2295872a9FFCac5425011c661',
    ES_GMX: '0xFf1489227BbAAC61a9209A08929E4c2a526DdD17',
    BN_GMX: '0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2',
    USDG: '0xc0253c3cC6aa5Ab407b5795a04c28fB063273894',
    ES_GMX_IOU: '0x6260101218eC4cCfFF1b778936C6f2400f95A954', // placeholder address

    StakedGmxTracker: '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342',
    BonusGmxTracker: '0x908C4D94D34924765f1eDc22A1DD098397c59dD4',
    FeeGmxTracker: '0x4d268a7d4C16ceB5a606c173Bd974984343fea13',
    StakedGlpTracker: '0x9e295B5B976a184B14aD8cd72413aD846C299660',
    FeeGlpTracker: '0xd2D1162512F927a7e282Ef43a362659E4F2a728F',

    StakedGmxDistributor: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
    StakedGlpDistributor: '0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720',

    GmxVester: '0x472361d3cA5F49c8E633FB50385BfaD1e018b445',
    GlpVester: '0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A',

    OrderBook: '0x4296e307f108B2f583FF2F7B7270ee7831574Ae5',
    OrderExecutor: '0x4296e307f108B2f583FF2F7B7270ee7831574Ae5',
    OrderBookReader: '0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B',

    PositionRouter: '0xffF6D276Bc37c61A23f06410Dce4A400f66420f8',
    PositionManager: '0xA21B83E579f4315951bA658654c371520BDcB866',

    TraderJoeGmxAvaxPool: '0x0c91a070f862666bbcce281346be45766d874d98',
    ReferralStorage: '0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8',
    ReferralReader: '0x505Ce16D3017be7D76a7C2631C0590E71A975083',

    // Synthetics
    DataStore: '0x2F0b22339414ADeD7D5F06f9D604c7fF5b2fe3f6',
    EventEmitter: '0xDb17B211c34240B014ab6d61d4A31FA0C0e20c26',
    ExchangeRouter: '0x11E590f6092D557bF71BaDEd50D81521674F8275',
    DepositVault: '0x90c670825d0C62ede1c5ee9571d6d9a17A722DFF',
    WithdrawalVault: '0xf5F30B10141E1F63FC11eD772931A8294a591996',
    OrderVault: '0xD3D60D22d415aD43b7e64b510D86A30f19B1B12C',
    SyntheticsReader: '0x73BA021ACF4Bb6741E82690DdB821e7936050f8C',
    SyntheticsRouter: '0x820F5FfC5b525cD4d88Cd91aCf2c28F16530Cc68',
    Timelock: '0x4Db91a1Fa4ba3c75510B2885d7d7da48E0209F38',

    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },

  [AVALANCHE_FUJI]: {
    Vault: AddressZero,
    Router: AddressZero,
    VaultReader: AddressZero,
    Reader: AddressZero,
    GlpManager: AddressZero,
    RewardRouter: AddressZero,
    RewardReader: AddressZero,
    GlpRewardRouter: AddressZero,
    NATIVE_TOKEN: '0x1D308089a2D1Ced3f1Ce36B1FcaF815b07217be3',
    GLP: AddressZero,
    GMX: AddressZero,
    ES_GMX: AddressZero,
    BN_GMX: AddressZero,
    USDG: AddressZero,
    ES_GMX_IOU: AddressZero,

    StakedGmxTracker: AddressZero,
    BonusGmxTracker: AddressZero,
    FeeGmxTracker: AddressZero,
    StakedGlpTracker: AddressZero,
    FeeGlpTracker: AddressZero,

    StakedGmxDistributor: AddressZero,
    StakedGlpDistributor: AddressZero,

    GmxVester: AddressZero,
    GlpVester: AddressZero,

    OrderBook: AddressZero,
    OrderExecutor: AddressZero,
    OrderBookReader: AddressZero,

    PositionRouter: AddressZero,
    PositionManager: AddressZero,

    TraderJoeGmxAvaxPool: AddressZero,
    ReferralStorage: '0x58726dB901C9DF3654F45a37DD307a0C44b6420e',
    ReferralReader: AddressZero,

    // Synthetics
    DataStore: '0xEA1BFb4Ea9A412dCCd63454AbC127431eBB0F0d4',
    EventEmitter: '0xc67D98AC5803aFD776958622CeEE332A0B2CabB9',
    ExchangeRouter: '0xCD5B8Ea4a848b1c576125f20F9aDe5F58FDf4D4f',
    DepositVault: '0x25D23e8E655727F2687CC808BB9589525A6F599B',
    WithdrawalVault: '0x74d49B6A630Bf519bDb6E4efc4354C420418A6A2',
    OrderVault: '0x25D23e8E655727F2687CC808BB9589525A6F599B',
    SyntheticsReader: '0xA7FF75f85E4fB219ede2FA08Fe4dE1635261de31',
    SyntheticsRouter: '0xfD9EA9e7757da026971a28EcA401F9FDc7ACA646',
    Timelock: AddressZero,

    Multicall: '0x0f53e512b49202a37c81c6085417C9a9005F2196',
  },
  [ZKSYNC_SEPOLIA]: {
    Vault: AddressZero,
    Router: '0x38f2543e8c6C6954E71fA1cAa2eb9d39fBE64107',
    VaultReader: AddressZero,
    Reader: '0xD9c04c3d2258438f8E38381E61e854f6dC8Caea2',
    GlpManager: AddressZero,
    RewardRouter: AddressZero,
    RewardReader: AddressZero,
    GlpRewardRouter: AddressZero,
    NATIVE_TOKEN: AddressZero,
    GLP: AddressZero,
    GMX: AddressZero,
    ES_GMX: AddressZero,
    BN_GMX: AddressZero,
    USDG: AddressZero,
    ES_GMX_IOU: AddressZero,

    StakedGmxTracker: AddressZero,
    BonusGmxTracker: AddressZero,
    FeeGmxTracker: AddressZero,
    StakedGlpTracker: AddressZero,
    FeeGlpTracker: AddressZero,

    StakedGmxDistributor: AddressZero,
    StakedGlpDistributor: AddressZero,

    GmxVester: AddressZero,
    GlpVester: AddressZero,

    OrderBook: AddressZero,
    OrderExecutor: AddressZero,
    OrderBookReader: AddressZero,

    PositionRouter: AddressZero,
    PositionManager: AddressZero,

    TraderJoeGmxAvaxPool: AddressZero,
    ReferralStorage: '0x63B333b0f5c2c90B727A300663b43C4182194671',
    ReferralReader: AddressZero,

    // Synthetics
    DataStore: '0xD5a9c50e65eBcF20DF8Df7d3f0D825D8202EBEfc',
    EventEmitter: '0x2011E4372D681AEB2B62DBFe9806b0C6c963eE1c',
    ExchangeRouter: '0xa64a7FdD8ab84157172a781691380316010204A3',
    SubaccountRouter: '0x95Ea17c2AaBeBc8cC88bcB25ae5C990C5d5e4E86',
    DepositVault: '0x1B8CB84caC7fbAb848670B331f49759C3c1d976f',
    WithdrawalVault: '0xC3A463123eF29Fab3b0935889ADd502B38CbB9aC',
    OrderVault: '0x964163A7b0F4C0E36F6684786261B786Ae4090c4',
    SyntheticsReader: '0xD9c04c3d2258438f8E38381E61e854f6dC8Caea2',
    SyntheticsRouter: '0x38f2543e8c6C6954E71fA1cAa2eb9d39fBE64107',
    Timelock: '0x14D492d792831cfE250213Be253F8b6C5196D75D',
    commonPool: '0x162C6c6b94c486dbc748C83D922a7eeAad9b1e3E',
    Multicall: '0x4Ba8154BB8285B710cAeF4A2546CAa31c8851EC8',
  },
  [ZKSYNC]: isProd
    ? {
        Vault: AddressZero,
        Router: '0xd06a1fA35C92281c5F8F00450d29180FCA7e98C1',
        VaultReader: AddressZero,
        Reader: '0x25A5cFB62a7461a3EEEC6e076DE522521298511b',
        GlpManager: AddressZero,
        RewardRouter: AddressZero,
        RewardReader: AddressZero,
        GlpRewardRouter: AddressZero,
        NATIVE_TOKEN: AddressZero,
        GLP: AddressZero,
        GMX: AddressZero,
        ES_GMX: AddressZero,
        BN_GMX: AddressZero,
        USDG: AddressZero,
        ES_GMX_IOU: AddressZero,

        StakedGmxTracker: AddressZero,
        BonusGmxTracker: AddressZero,
        FeeGmxTracker: AddressZero,
        StakedGlpTracker: AddressZero,
        FeeGlpTracker: AddressZero,

        StakedGmxDistributor: AddressZero,
        StakedGlpDistributor: AddressZero,

        GmxVester: AddressZero,
        GlpVester: AddressZero,

        OrderBook: AddressZero,
        OrderExecutor: AddressZero,
        OrderBookReader: AddressZero,

        PositionRouter: AddressZero,
        PositionManager: AddressZero,

        TraderJoeGmxAvaxPool: AddressZero,
        ReferralStorage: '0xEc7bad664e0dD84348c38f229F56c865Ec49AA23',
        ReferralReader: AddressZero,

        // Synthetics
        DataStore: '0x895124783008C6c68eFcccac24c482Fdf30439B2',
        EventEmitter: '0x9F006F3a4177e645Fc872B911Cf544E890c82B1A',
        ExchangeRouter: '0x36F6469B33c2cAE33beC387852062413BBA70262',
        SubaccountRouter: '0xb3a3271Bd4d9E95c0Ff58C9D967778a4330e0297',
        DepositVault: '0x252e8f48694b2ec03A92eef298F986A7b5cE3B71',
        WithdrawalVault: '0xe62d220DEF5d1656447289fA001cFc69a8Af1fb7',
        OrderVault: '0x21150394A988FD88b18492611df005372cAe998D',
        SyntheticsReader: '0x25A5cFB62a7461a3EEEC6e076DE522521298511b',
        SyntheticsRouter: '0xd06a1fA35C92281c5F8F00450d29180FCA7e98C1',
        Timelock: '0x28e84f14Bb9d06089f6F301d2D1729d6255d0C57',
        commonPool: '0xB0D458769c8AEA64128225e8aBbbCEe8f6A1DFab',
        merkelDistributor: '0xCD31892C27178572e8047944Ad710B62f6E46Af7',

        Multicall: '0x24553F35EF600372Ad06Dd096309Bb0110b9D911',
      }
    : {
        Vault: AddressZero,
        Router: '0xF028b432cB1012e6dE5D1b3D81D77f5059DDb3d8',
        VaultReader: AddressZero,
        Reader: '0x71384FE85F5bbDb1C3de9DdAE05eCe456f18Fe5b',
        GlpManager: AddressZero,
        RewardRouter: AddressZero,
        RewardReader: AddressZero,
        GlpRewardRouter: AddressZero,
        NATIVE_TOKEN: AddressZero,
        GLP: AddressZero,
        GMX: AddressZero,
        ES_GMX: AddressZero,
        BN_GMX: AddressZero,
        USDG: AddressZero,
        ES_GMX_IOU: AddressZero,

        StakedGmxTracker: AddressZero,
        BonusGmxTracker: AddressZero,
        FeeGmxTracker: AddressZero,
        StakedGlpTracker: AddressZero,
        FeeGlpTracker: AddressZero,

        StakedGmxDistributor: AddressZero,
        StakedGlpDistributor: AddressZero,

        GmxVester: AddressZero,
        GlpVester: AddressZero,

        OrderBook: AddressZero,
        OrderExecutor: AddressZero,
        OrderBookReader: AddressZero,

        PositionRouter: AddressZero,
        PositionManager: AddressZero,

        TraderJoeGmxAvaxPool: AddressZero,
        ReferralStorage: '0x4669588Cd48Df1017A170b215bf1731c85455Db1',
        ReferralReader: AddressZero,

        // Synthetics
        DataStore: '0x4F6beE9Bc2562df95e1A1D55e48D70a00DBB3379',
        EventEmitter: '0x850c4603e46feB6Dd13c16B638Bf2CB7c520f75A',
        ExchangeRouter: '0x1404a9a7aF54c01b4a205330Ca91aA434e08AC83',
        SubaccountRouter: '0x1e824E7f99dF0941e0561D27a47dD3693BaFD8D9',
        DepositVault: '0x1717B74EfB03303c82451f1a3A38cE06E3f30Cb3',
        WithdrawalVault: '0x847A2cC2bE438A25117843e4a890569e02f1f20F',
        OrderVault: '0xbA1c8d37fF92377Be2B0731982DB0003A37AC2C2',
        SyntheticsReader: '0x71384FE85F5bbDb1C3de9DdAE05eCe456f18Fe5b',
        SyntheticsRouter: '0xF028b432cB1012e6dE5D1b3D81D77f5059DDb3d8',
        Timelock: '0x780808e6839A655B641DF96D4A5ABB35dB62Ab23',
        commonPool: '0x4E724712063CB60C0E808432198c3794495b99E6',
        merkelDistributor: '0xCD31892C27178572e8047944Ad710B62f6E46Af7',

        Multicall: '0xf1f3e1d4b88D9Ab8746FaB7e5083c9561Bf1e8D3',
      },
}

export function getContract(chainId: number, name: string): Address {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`)
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`)
  }

  return CONTRACTS[chainId][name] as Address
}
