import { APRTooltip } from '@components/earn/APRTooltip'
import Tooltip from '@components/shared/Tooltip'
import { AprInfo } from 'components/AprInfo/AprInfo'
import { particlesBoost } from '../../../../config/earn'
import { BigNumber, ethers } from 'ethers'
import { useBonusAPR } from 'hooks/useBonusAPR'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import { useMemo } from 'react'
import { USD_DECIMALS } from 'rfx/lib/legacy'
import { cn } from 'utils/classnames'
import Image from 'next/image'

export const ignitePools: Record<string, string> = {
  'ETH/USD [wETH-USDC.e]': '0x8EFA54951bF70D9775DFe8F9364df83aD1e1a8cF',
  'BTC/USD [wETH-USDC.e]': '0x62170Af269E9Acd09a89279C0485e89aA42857A3',
  'ZK/USD [ZK-USDC.e]': '0x9D4d54c8661a17604A46B849DED78Cf20127fB92',
}

export const getBoostandTotalTVLPool = (
  market: PoolDataType,
  totalTVLgrouped: {
    ZK: BigNumber
    'wETH-USDC.e': BigNumber
    stETH: BigNumber
  },
) => {
  const boost: string =
    particlesBoost[`${market.marketIndexName} [${market.marketPoolName}]`]
      ?.boost

  const group: keyof typeof totalTVLgrouped = market.marketPoolName.includes(
    'ZK',
  )
    ? 'ZK'
    : market.marketPoolName.includes('stETH') ||
        market.marketPoolName.includes('USDC.e-deUSD')
      ? 'stETH'
      : 'wETH-USDC.e'
  const totalTVLPool = totalTVLgrouped[group]

  return { boost, totalTVLPool, group }
}

export const calculateTotalAPR = (
  market: PoolDataType,
  bonusAPR?: BigNumber,
  igniteAPR?: BigNumber,
) => {
  const marketAPR = market.apr || BigNumber.from(0)

  let _bonusAPR = +ethers.utils.formatUnits(
    bonusAPR || BigNumber.from(0),
    USD_DECIMALS,
  )

  _bonusAPR = parseInt((_bonusAPR * 100).toString())
  return marketAPR
    .add(BigNumber.from(_bonusAPR))
    .add(BigNumber.from(igniteAPR || 0))
}

export const APRCell = ({
  market,
  totalTVLgrouped,
  className,
  merklData,
}: {
  market: PoolDataType
  totalTVLgrouped: {
    ZK: BigNumber
    'wETH-USDC.e': BigNumber
    stETH: BigNumber
  }
  className?: string
  merklData?: Record<string, { apr: number }>
}) => {
  const { boost, totalTVLPool, group } = getBoostandTotalTVLPool(
    market,
    totalTVLgrouped,
  )

  const ignitePoolAddress =
    ignitePools[`${market.marketIndexName} [${market.marketPoolName}]`]

  const bonusAPR = useBonusAPR(group, totalTVLPool)

  const apr = useMemo(() => {
    const igniteApr = parseInt(
      ((merklData?.[ignitePoolAddress]?.apr || 0) * 100).toString(),
    )
    return calculateTotalAPR(market, bonusAPR, BigNumber.from(igniteApr))
  }, [market?.apr, bonusAPR, merklData, ignitePoolAddress])
  const igniteAPR = merklData?.[ignitePoolAddress]?.apr || 0

  return (
    <div className="flex items-center gap-1">
      {!!(market?.apr?.gt(0) || bonusAPR?.gt(0) || igniteAPR > 0) ? (
        <Tooltip
          className="w-[230px]"
          placement="top"
          content={
            <APRTooltip
              baseAPR={market.apr || BigNumber.from(0)}
              boost={boost ? `${boost}X Particle Boost` : '-'}
              bonusAPR={bonusAPR}
              igniteAPR={igniteAPR}
            />
          }
        >
          <AprInfo
            apr={apr}
            incentiveApr={market.incentiveApr}
            className={cn('!text-base', className)}
          />
        </Tooltip>
      ) : (
        <AprInfo
          apr={apr}
          incentiveApr={market.incentiveApr}
          className={cn('!text-base', className)}
        />
      )}
      {igniteAPR ? (
        <Image src="/icons/fire.svg" alt="" width={16} height={16} />
      ) : null}
    </div>
  )
}
