/* eslint-disable @typescript-eslint/no-explicit-any */
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import '@rainbow-me/rainbowkit/styles.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'components/LeverageSlider/LeverageSlider.scss'
import 'components/TokenSelector/TokenSelector.scss'
import 'components/Tooltip/Tooltip.scss'
import { swrGCMiddleware } from 'rfx/lib/swrMiddlewares'
import { useRealChainIdWarning } from 'hooks/useRealChainId'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import 'react-toastify/dist/ReactToastify.css'
import { SWRConfig } from 'swr'
import {
  zksync,
  arbitrum,
  base,
  linea,
  mainnet,
  optimism,
  polygon,
} from 'viem/chains'
import { WagmiProvider } from 'wagmi'
import { myCustomTheme } from '../config/rainbowkit-theme'
import '../styles/datepicker.css'
import '../styles/globals.css'
import '../styles/performance-calendar.css'
import '../styles/stats.css'
import Head from 'next/head'
import { meta } from './_document'

const FullApp = dynamic(() => import('@components/FullApp'))
const RainbowKitProvider = dynamic(() =>
  import('@rainbow-me/rainbowkit').then((module) => module.RainbowKitProvider),
)

export const queryClient = new QueryClient()
export const wagmiConfig = getDefaultConfig({
  appName: 'RFX',
  projectId: '7c492019a90462c70a242dadd08f5a66',
  chains: [arbitrum, base, linea, mainnet, optimism, polygon, zksync],
  ssr: true, // If your dApp uses server side rendering (SSR)
})

// Do not add hooks to this component, that will cause unnecessary rerenders
// Top level state hydrating/updating should go in MangoProvider
function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/rfx-favicon/rfx-favicon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/rfx-favicon/rfx-favicon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/rfx-favicon/rfx-favicon.png"
        />
        <link rel="manifest" href="/rfx-favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/rfx-favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta property="og:title" content={meta.title} />
        <meta name="description" content={meta.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="/rfx-favicon/rfx-favicon.png"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta property="og:image" content={meta.image} />

        <meta name="theme-color" content="#ffffff" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@RFX_exchange" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
        <meta name="google" content="notranslate" />
        <link rel="manifest" href="/manifest.json"></link>
      </Head>
      <SWRConfig
        value={{
          refreshInterval: 50000,
          refreshWhenHidden: false,
          refreshWhenOffline: false,
          use: [swrGCMiddleware as any],
        }}
      >
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiConfig}>
            <RainbowKitProvider
              // showRecentTransactions={false}
              theme={myCustomTheme}
            >
              <FullAppWrapper Component={Component} pageProps={pageProps} />
            </RainbowKitProvider>
          </WagmiProvider>
        </QueryClientProvider>
      </SWRConfig>
    </>
  )
}

const FullAppWrapper = (p: any) => {
  useRealChainIdWarning()

  return <FullApp Component={p.Component} pageProps={p.pageProps} />
}

export default appWithTranslation(MyApp)
