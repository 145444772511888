import Document, { Html, Head, Main, NextScript } from 'next/document'
import Script from 'next/script'
import { cn } from 'utils/classnames'
import { flexosoft, ttCommons, alpha } from 'utils/fonts'

export const meta = {
  title: `RFX | Decentralized Perpetual Exchange`,
  description:
    'RFX is a cutting-edge decentralised perpetual futures platform set to revolutionise the derivatives landscape on zkSync Era. RFX stands apart with its forward-thinking architecture, featuring a multi-pool model, advanced low-latency oracle networks, automated risk engine, and innovative veTokenomics. Built upon the principles of capital efficiency, LP risk management, and composability, RFX brings a Uniswap-style "on-rails" permissionless market creation to the derivatives realm.',
  image: '/rfx-og.png',
  type: 'exchange',
}

class MyDocument extends Document {
  render() {
    return (
      <Html>
        <Head>
          <Script
            async
            src="/datafeeds/udf/dist/bundle.js"
            strategy="beforeInteractive"
          ></Script>
        </Head>
        <body
          className={cn(
            'hide-scroll',
            ttCommons.variable,
            flexosoft.variable,
            alpha.variable,
          )}
        >
          <Main />
          <NextScript />
        </body>
      </Html>
    )
  }
}

export default MyDocument
