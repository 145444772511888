import {
  getDefaultConfig,
  getDefaultWallets,
  WalletList,
} from '@rainbow-me/rainbowkit'
import {
  coinbaseWallet,
  coreWallet,
  okxWallet,
  trustWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { createPublicClient, http } from 'viem'
import {
  arbitrum,
  base,
  linea,
  mainnet,
  optimism,
  polygon,
  zksync,
} from 'viem/chains'

const WALLET_CONNECT_PROJECT_ID = '7c492019a90462c70a242dadd08f5a66'
const APP_NAME = 'RFX'

export const publicClient = (chainId: any) =>
  createPublicClient({
    chain: chainId,
    transport: http(''),
  })

const { wallets } = getDefaultWallets({
  appName: APP_NAME,
  projectId: WALLET_CONNECT_PROJECT_ID,
})

const walletList: WalletList = [
  ...wallets,
  {
    groupName: 'Others',
    wallets: [coinbaseWallet, trustWallet, coreWallet, okxWallet],
  },
]

export const rainbowKitConfig = getDefaultConfig({
  appName: APP_NAME,
  projectId: WALLET_CONNECT_PROJECT_ID,
  chains: [arbitrum, base, linea, mainnet, optimism, polygon, zksync],
  transports: {
    [zksync.id]: http(),
    [arbitrum.id]: http(),
    [base.id]: http(),
    [linea.id]: http(),
    [mainnet.id]: http(),
    [optimism.id]: http(),
    [polygon.id]: http(),
  },
  wallets: walletList,
})
