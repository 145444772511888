import { Theme } from '@rainbow-me/rainbowkit'
import tailwindConfig from '../tailwind.config'

const { theme } = tailwindConfig

export const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: '30px',
  },
  colors: {
    accentColor: theme.extend.colors['th-bkg-3'],
    accentColorForeground: theme.extend.colors['th-fgd-1'],
    actionButtonBorder: theme.extend.colors.black,
    actionButtonBorderMobile: theme.extend.colors.black,
    actionButtonSecondaryBackground: `${theme.extend.colors['th-fgd-1']}/10`,
    closeButton: theme.extend.colors['th-fgd-3'],
    closeButtonBackground: theme.extend.colors.black,
    connectButtonBackground: `${theme.extend.colors['th-fgd-1']}/10`,
    connectButtonBackgroundError: theme.extend.colors['th-error'],
    connectButtonInnerBackground: `${theme.extend.colors['th-fgd-1']}/10`,
    connectButtonText: theme.extend.colors['th-bkg-1'],
    connectButtonTextError: theme.extend.colors['th-error'],
    connectionIndicator: '',
    error: theme.extend.colors['th-error'],
    downloadBottomCardBackground: '',
    downloadTopCardBackground: '',
    generalBorder: `${theme.extend.colors['th-fgd-1']}/10`,
    generalBorderDim: '',
    menuItemBackground: `${theme.extend.colors['th-fgd-1']}/10`,
    modalBackdrop: `${theme.extend.colors['th-bkg-1']}/10`,
    modalBackground: theme.extend.colors.black,
    modalBorder: `${theme.extend.colors['th-fgd-1']}/10`,
    modalText: theme.extend.colors['th-fgd-1'],
    modalTextDim: theme.extend.colors['th-fgd-2'],
    modalTextSecondary: theme.extend.colors['th-fgd-2'],
    profileAction: `${theme.extend.colors['th-fgd-1']}/10`,
    profileActionHover: `${theme.extend.colors['th-fgd-1']}/10`,
    profileForeground: theme.extend.colors['th-bkg-1'],
    selectedOptionBorder: `${theme.extend.colors['th-input-border-secondary']}`,
    standby: theme.extend.colors['th-fgd-1'],
  },
  fonts: {
    body: 'var(--font-body)',
  },
  radii: {
    actionButton: '17px',
    connectButton: '17px',
    menuButton: '10px',
    modal: '17px',
    modalMobile: '10px',
  },
  shadows: {
    connectButton: '',
    dialog: theme.extend.colors['th-bkg-1'],
    profileDetailsAction: '',
    selectedOption: `${theme.extend.colors['th-fgd-1']}/10`,
    selectedWallet: `${theme.extend.colors['th-fgd-1']}/10`,
    walletLogo: `${theme.extend.colors['th-fgd-1']}/10`,
  },
}
