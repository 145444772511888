import { IS_VERBOSE } from 'config/development'
import { ETH_MAINNET, ZKSYNC, ZKSYNC_SEPOLIA } from './chains'
import { isDevelopment } from './env'
import { getSubgraphUrlKey } from './localStorage'
import { isProd } from './constants'

const SUBGRAPH_URLS: any = {
  [ZKSYNC_SEPOLIA]: {
    stats: 'https://api.studio.thegraph.com/query/62681/rfx-2-1/version/latest',
    referrals:
      'https://api.studio.thegraph.com/query/62681/rfx-referrals/version/latest',
    syntheticsStats:
      'https://api.studio.thegraph.com/query/62681/rfx-2-1/version/latest',
    leaderboard:
      'https://api.studio.thegraph.com/query/62681/rfxl-2-1/version/latest',
  },
  [ZKSYNC]: isProd
    ? {
        stats:
          'https://api.studio.thegraph.com/query/62681/rfxs-master/version/latest',
        referrals:
          'https://api.studio.thegraph.com/query/62681/rfxr-master/version/latest',
        syntheticsStats:
          'https://api.studio.thegraph.com/query/62681/rfxs-master/version/latest',
        leaderboard:
          'https://api.studio.thegraph.com/query/62681/rfxl-master/version/latest',
      }
    : {
        stats:
          'https://api.studio.thegraph.com/query/62681/rfxs-internal/version/latest',
        referrals:
          'https://api.studio.thegraph.com/query/62681/rfxr-internal/version/latest',
        syntheticsStats:
          'https://api.studio.thegraph.com/query/62681/rfxs-internal/version/latest',
        leaderboard:
          'https://api.studio.thegraph.com/query/62681/rfxl-internal/version/latest',
      },

  common: {
    [ETH_MAINNET]: {
      chainLink: 'https://api.thegraph.com/subgraphs/name/deividask/chainlink',
    },
  },
}

export function getSubgraphUrl(chainId: number, subgraph: string) {
  if (isDevelopment()) {
    const localStorageKey = getSubgraphUrlKey(chainId, subgraph)
    const url = localStorage.getItem(localStorageKey)
    if (url) {
      IS_VERBOSE &&
        // eslint-disable-next-line no-console
        console.warn(
          '%s subgraph on chain %s url is overriden: %s',
          subgraph,
          chainId,
          url,
        )
      return url
    }
  }

  return SUBGRAPH_URLS?.[chainId]?.[subgraph]
}
